declare global {
  interface Window {
    gitlify__cookiebanner__config: {
      config: any;
    };
  }
}

export const userConfig =
  (window.gitlify__cookiebanner__config || {}).config || {};
