import { onlyInEU } from "./config/onlyInEU";
import { resetExpiryOnCookie } from "./config/resetExpiryOnCookie";
import {
  acceptAll,
  hasAllAccepted,
  shouldShowCookieBanner,
  updatePreferences,
} from "./preferences";
import { initTracker } from "./track";

try {
  const show = shouldShowCookieBanner();

  const tracker = initTracker();

  if (hasAllAccepted()) {
    tracker.load();
  }

  const loadCookieBanner = () =>
    import("./cookiebanner").then((loadCookieBanner) =>
      loadCookieBanner.loadCookieBanner({
        onAcceptAll: () => tracker.load(),
        track: window.tracker.track,
      })
    );

  if (show) {
    if (onlyInEU) {
      // @ts-ignore
      import("@segment/in-eu").then((inEU) => {
        if (inEU.default()) {
          loadCookieBanner();
        } else {
          acceptAll();

          tracker.load();
        }
      });
    } else {
      loadCookieBanner();
    }
  } else {
    if (resetExpiryOnCookie) {
      // Resets the cookies to their value
      updatePreferences({});
    }
  }
} catch (e) {
  const message = `E5478: Some weird error happens if cookiebanner loads: ${e.message}`;
  console.error(message);
}
