import { domain } from "./domain";
import { userConfig } from "./userConfig";

export const cookieAttributes = {
  path: "",
  domain: domain,
  sameSite: "lax",
  expires: 365,
  ...userConfig.cookieAttributes,
};

export const denyCookieAttributes = {
  ...cookieAttributes,
  expires: 1,
  ...userConfig.denyCookieAttributes,
};
