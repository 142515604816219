import { loadSrc } from "../loadSrc";
import {
  MinimalTracker,
} from "../Tracker";

declare global {
  interface Window {
    plausible: (...args: any[]) => void
  }
}

const init = (): ((...args: any[]) => void) => {
  window.plausible =
    window.plausible ||
    function () {
      ((window.plausible as any).q = (window.plausible as any).q || []).push(arguments);
    };

  return (...args: any[]) => window.plausible(args);
};

// doesn't require consent, is anonymous
// https://plausible.io/docs/plausible-script

export const PlausibleIO = (): MinimalTracker<{}> => {
  const plausible = init();

  return {
    load: (_: {}) => {
      loadSrc({
        src: "https://plausible.io/js/plausible.js",
        defer: true,
        async: true,
        data: {
          domain: window.location.hostname.replace(/^www\./, ""),
        },
      });
    },
    track: (args) => plausible(args.eventName, { props: args.payload }),
  };
};
