type Args = {
  src: string;
  data?: { [key: string]: string };
  [key: string]: string | boolean | number | any;
};

export const loadSrc = ({ src, data, ...attr }: Args) =>
  new Promise((res, rej) => {
    // Create an async script element based on your key.
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = src;

    Object.entries(data || {}).map(([k, v]) => (script.dataset[k] = v));
    Object.entries(attr || []).map(([k, v]) => ((script as any)[k] = v));

    // Insert our script next to the first script element.
    var first = document.getElementsByTagName("script")[0];

    first.onload = res;
    first.onerror = rej;

    if (first.parentNode) {
      first.parentNode.insertBefore(script, first);
    } else {
      document.head.appendChild(script);
    }
  });
