export type MinimalTracker<Config = any> = {
  load: (config: Config) => void;
  track: (args: {
    eventName: string;
    payload?: {
      [key: string]: string;
    };
  }) => void;
};

export type Tracker<Config = any> = MinimalTracker<Config> & {
  identify: (args: {
    userId?: string;
    anonId?: string;
    email?: string;
    github_name?: string;
    position?: string;
  }) => void;
  page: (args: {
    category: string;
    name: string;
    payload: {
      [key: string]: string;
    };
  }) => void;
  group: (args: {
    groupId: string;
    payload?: {
      [key: string]: string;
    };
  }) => void;
  alias: (args: { oldId: string; newId: string }) => void;
};

export function constructTrackerFromMinimal<Config>(
  minimal: MinimalTracker<Config>
): Tracker<Config> {
  return {
    ...minimal,
    identify: (args) =>
      minimal.track({
        eventName: "identify",
        payload: args,
      }),
    page: (args) =>
      minimal.track({
        eventName: [`page`, args.category, args.name]
          .filter(Boolean)
          .join("__"),
        payload: args.payload,
      }),
    group: (args) =>
      minimal.track({
        eventName: "group",
        payload: {
          ...args.payload,
          groupId: args.groupId,
        },
      }),
    alias: (args) =>
      minimal.track({
        eventName: "alias",
        payload: args,
      }),
  };
}

export const combineTrackers = (
  x: Array<Partial<Tracker> & MinimalTracker>
): Tracker => {
  return {
    load: (_: any) => {
      console.error(`E4578: not implemented.`);
    },
    identify: (_: any) => x.forEach((y) => y["identify"] && y["identify"](_)),
    page: (_: any) => x.forEach((y) => y["page"] && y["page"](_)),
    group: (_: any) => x.forEach((y) => y["group"] && y["group"](_)),
    alias: (_: any) => x.forEach((y) => y["alias"] && y["alias"](_)),
    track: (_: any) => x.forEach((y) => y.track(_)),
  };
};
