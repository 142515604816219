export const loadOnReady = (fn: () => void) => {
  if (document.readyState === "complete") {
    console.log(`loadOnReady`, "complete");
    fn();
  } else {
    console.log(`loadOnReady`, "load");

    window.addEventListener("load", fn);
  }
};
