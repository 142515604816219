import { tableOpts } from "./tableOpts";

export const cookieKeys = Object.keys(tableOpts.rows);

export const negativeCookieValue = Object.keys(tableOpts.rows).reduce(
  (acc, key) => ({
    ...acc,
    [key]: false,
    required: true,
  }),
  {}
);

export const positiveCookieValue = Object.keys(tableOpts.rows).reduce(
  (acc, key) => ({
    ...acc,
    [key]: true,
    required: true,
  }),
  {}
);
