import { loadOnReady } from "../loadOnReady";
import { GA4 } from "./libs/ga4";
import { PlausibleIO } from "./libs/plausible";
import { Segment } from "./libs/segment";
import { combineTrackers, Tracker } from "./Tracker";

import { ga4Config } from "../config/track/ga4";
import { segmentConfig } from "../config/track/segment";

declare global {
  interface Window {
    tracker: Tracker<any>;
  }
}

const earliestParentNodeWith = (
  el: HTMLElement | ParentNode | undefined | null,
  attribute: string
): null | {
  element: HTMLElement | ParentNode;
  eventName?: string;
} => {
  if (!el) {
    return null;
  }

  if ("dataset" in el && el.dataset[attribute]) {
    return {
      element: el,
      eventName: el.dataset[attribute],
    };
  }

  if ("parentNode" in el) {
    return earliestParentNodeWith(el.parentNode, attribute);
  }

  return null;
};

const getTrackingPayload = (element: HTMLElement, attribute: string) => {
  if (!element) {
    return;
  }

  const payload: {
    [key: string]: string;
  } = {};

  const prefix = `${attribute}-`;

  Object.keys(element.dataset)
    .filter((x) => x.indexOf(prefix) === 0)
    .forEach((trackKey: string) => {
      (payload as any)[trackKey.replace(prefix, ``)] =
        element.dataset[trackKey];
    });

  return payload;
};

const addBodyListener = (track: Tracker["track"]) => {
  const dataAttribute = "tracking";
  analytics.debug(true);

  document.body.addEventListener("click", (event: { target: any }) => {
    try {
      const { target } = event;

      if (!target) {
        return;
      }

      const { element, eventName } =
        earliestParentNodeWith(target, dataAttribute) || {};

      if (element && "dataset" in element && eventName) {
        const payload = getTrackingPayload(element, dataAttribute);

        track({
          eventName,
          payload: payload || {},
        });
      }
    } catch (e) {
      console.error(`E7845: error tracking`, e, event);
    }
  });
};

const ga4 = GA4();
const segment = Segment();
const pausible = PlausibleIO();

window.tracker = window.tracker || combineTrackers([segment, ga4, pausible]);

// GDPR conform
pausible.load({});

export const initTracker = () => {
  return {
    load: () => {
      ga4.load(ga4Config);

      segment.load(segmentConfig);

      loadOnReady(() => addBodyListener(window.tracker.track));
    },
  };
};
