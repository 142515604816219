import { reportWebVitals } from "../../reportWebVitals";
import { loadSrc } from "../loadSrc";
import { constructTrackerFromMinimal, Tracker } from "../Tracker";

type GA4Config = {
  measurementId: string;
};

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const init = (): ((...args: any[]) => void) => {
  window.dataLayer = window.dataLayer || [];

  return (...args: any[]) => window.dataLayer.push(args);
};

export const GA4 = (): Tracker<GA4Config> => {
  const gtag = init();

  return constructTrackerFromMinimal({
    load: (config: GA4Config) => {
      gtag("js", new Date());

      gtag("config", config.measurementId);

      loadSrc({
        src: `https://www.googletagmanager.com/gtag/js?id=${config.measurementId}`,
      });

      reportWebVitals(gtag);
    },
    track: ({ eventName, payload }) => gtag(eventName, payload),
  });
};
