import { loadSrc } from "../loadSrc";
import { Scope, scopes } from "../scopes";
import { Tracker } from "../Tracker";

type Analytics = {
  invoked?: any;
  initialize?: any;
  methods: typeof scopes;
  _loadOptions?: SegmentAnalytics.SegmentOpts;
  SNIPPET_VERSION: string;
  factory: (method: Scope) => (...args: any[]) => void;
} & SegmentAnalytics.AnalyticsJS;

declare global {
  interface Window {
    analytics: Analytics;
  }
}

export type SegmentConfig = {
  writeKey: string;
  options?: SegmentAnalytics.SegmentOpts;
};

const init = (): Analytics => {
  // Create a queue, but don't obliterate an existing one!
  var analytics = (window.analytics = window.analytics || ([] as any));

  // If the real analytics.js is already on the page return.
  if (analytics.initialize) return window.analytics;

  // If the snippet was invoked already show an error.
  if (analytics.invoked) {
    if (window.console && console.error) {
      console.error("Segment snippet included twice.");
    }
    return window.analytics;
  }

  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true;

  // A list of the methods in Analytics.js to stub.
  analytics.methods = scopes;

  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = function (method: Scope) {
    return function () {
      var args = Array.prototype.slice.call(arguments);
      args.unshift(method);

      (analytics as any).push(args);
      return window.analytics;
    };
  };

  // For each of our methods, generate a queueing stub.
  for (var i = 0; i < analytics.methods.length; i++) {
    var key = analytics.methods[i];
    (analytics as any)[key] = analytics.factory(key);
  }

  // Define a method to load Analytics.js from our CDN,
  // and that will be sure to only ever load it once.
  analytics.load = function (
    key: string,
    options?: SegmentAnalytics.SegmentOpts
  ) {
    const src =
      "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";
    // Create an async script element based on your key.
    loadSrc({ src });

    // Insert our script next to the first script element.
    analytics._loadOptions = options;
  };

  // Add a version to keep track of what's in the wild.
  analytics.SNIPPET_VERSION = "4.1.0";

  // Load Analytics.js with your key, which will automatically
  // load the tools you've enabled for your account. Boosh!

  // Make the first page call to load the integrations. If
  // you'd like to manually name or tag the page, edit or
  // move this call however you'd like.
  analytics.page();

  return window.analytics;
};

export const Segment = (): Tracker<SegmentConfig> => {
  window.analytics = init();

  return {
    load: (config: SegmentConfig) =>
      window.analytics.load(config.writeKey, config.options),
    identify: ({ userId, ...payload }) => {
      if (userId) {
        window.analytics.identify(userId, payload);
      } else {
        window.analytics.identify(payload);
      }
    },
    alias: ({ newId, oldId }) => {
      window.analytics.alias(newId, oldId);
    },
    track: ({ eventName, payload }) => {
      window.analytics.track(eventName, payload);
    },
    group: ({ groupId, payload }) => {
      window.analytics.group(groupId, payload);
    },
    page: ({ category, name, payload }) =>
      window.analytics.page(category, name, payload),
  };
};
