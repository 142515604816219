import Cookies from "js-cookie";
import { cookieAttributes } from "./config/cookieAttributes";
import {
  cookieKeys,
  negativeCookieValue,
  positiveCookieValue,
} from "./config/cookieKeys";
import { cookiename } from "./config/cookiename";

const parseValue = (v: string) => {
  const parsedValue = JSON.parse(v);

  const invalidEntries = cookieKeys.filter(
    (x) => typeof parsedValue[x] !== `boolean`
  );

  if (invalidEntries.length) {
    throw new Error(
      `E4785: The entries in ${v} are not of type boolean for: ${invalidEntries.join(
        "; "
      )}`
    );
  }

  return parsedValue;
};

export const getPreferences = () => {
  const v = Cookies.get(cookiename);

  if (!v) {
    return v;
  }

  try {
    return parseValue(v);
  } catch (e) {
    console.error(
      `E47854: Not able to parse cookie value ${v} for key ${cookiename}`,
      e.message
    );

    return undefined;
  }
};

export const shouldShowCookieBanner = () => !Boolean(getPreferences());

export const setPreferences = (opts: { [key: string]: boolean }) => {
  const value = JSON.stringify(opts);
  try {
    parseValue(value);
    Cookies.set(cookiename, value, cookieAttributes);

    const v = getPreferences();

    if (v) {
      return v;
    } else {
      throw new Error(`E4675: Not able to persist cookie`);
    }
  } catch (e) {
    console.error(e.message);

    return negativeCookieValue;
  }
};

export const updatePreferences = (opts: { [key: string]: boolean }) => {
  const value = getPreferences() || negativeCookieValue;
  return setPreferences({
    ...value,
    opts,
  });
};

export const acceptAll = () => setPreferences(positiveCookieValue);
export const denyAll = () => setPreferences(negativeCookieValue);

export const hasAllAccepted = () => {
  const v = getPreferences();

  if (!v) {
    return false;
  }

  return cookieKeys.map((k) => v[k]).reduce((x, y) => x && y, true);
};
